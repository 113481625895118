import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Button,
  Card,
  Checkbox,
  Divider,
  Icon,
  MoreButton,
  Search,
  Tag,
  DatePicker,
} from '@makeably/creativex-design-system';
import DsTabs from 'components/molecules/DsTabs';
import FilterTags from 'components/molecules/FilterTags';
import { addToast } from 'components/organisms/Toasts';
import CoreAssetButton from 'components/preflights/CoreAssetButton';
import CreativeCell from 'components/preflights/CreativeCell';
import PreflightsFilter from 'components/preflights/PreflightsFilter';
import SubmitAsCoreAssetModal from 'components/preflights/SubmitAsCoreAssetModal';
import SubmittedPreflightsTable from 'components/preflights/SubmittedPreflightsTable';
import {
  initialDateRange,
} from 'components/reporting/shared';
import { optionArrayProps } from 'components/shared';
import { arrayIf } from 'utilities/array';
import { saveUrlFile } from 'utilities/file';
import { getObjFilterTest } from 'utilities/filtering';
import { track } from 'utilities/mixpanel';
import { get } from 'utilities/requests';
import {
  newPreflightPretestPath,
  pdfDataPreflightPretestPath,
  preflightPretestsPath,
  preflightsPreflightPretestsPath,
} from 'utilities/routes';
import { dateToString } from 'utilities/string';
import styles from './SubmittedPreflights.module.css';

const MAX_PER_PAGE = 20;
const NULL_VALUE = '--';
const HIGHEST_VALUE = 'highest';
const ALL_TAB = 'all';
const USER_TAB = 'user';
const MAX_SELECTABLE_ASSETS = 500;
const ID_DELIM = '_';
const DATE_GAP = 4;

const propTypes = {
  creativeLifecycle: PropTypes.shape({
    coreAssetIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    creativeLifecycleCampaigns: PropTypes.arrayOf(
      PropTypes.shape({
        brand: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ).isRequired,
    measurementPartners: optionArrayProps.isRequired,
    partners: optionArrayProps.isRequired,
  }).isRequired,
  featureAccess: PropTypes.shape({
    canViewTabs: PropTypes.bool.isRequired,
    displayBrand: PropTypes.bool.isRequired,
    displayQuality: PropTypes.bool.isRequired,
    isCreativeLifecycleEnabled: PropTypes.bool.isRequired,
  }).isRequired,
  scores: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    rankLabels: PropTypes.object.isRequired,
    scoreId: PropTypes.number.isRequired,
    scoreName: PropTypes.string.isRequired,
  }).isRequired,
  userId: PropTypes.number.isRequired,
  youtubeType: PropTypes.string.isRequired,
  currentTab: PropTypes.string,
};

const defaultProps = {
  currentTab: USER_TAB,
};

function headers(displayBrand, displayQuality, allChecked, onAllChecked, showCheckbox) {
  let conditionalHeaders = [];

  if (displayBrand) conditionalHeaders.push({ value: 'Branded' });

  if (displayQuality) {
    conditionalHeaders.unshift({
      className: styles.highQualityColumn,
      value: 'High Quality Creatives',
    });
    conditionalHeaders = conditionalHeaders.concat([
      { value: 'Score' },
      { value: 'Rules Met' },
    ]);
  }

  return [
    ...arrayIf(
      showCheckbox,
      { value: <Checkbox checked={allChecked} onChange={onAllChecked} /> },
    ),
    { value: '' },
    { value: 'Name' },
    { value: 'Submitted' },
    { value: 'Channel' },
    ...conditionalHeaders,
    { value: 'PDF' },
    { value: '' },
  ];
}

function renderResultCell(result) {
  if (typeof result === 'string') return result;

  const color = result ? 'green' : 'red';
  const icon = result ? 'checkCircle' : 'exclamationCircle';

  return <Icon color={color} name={icon} />;
}

async function onPdfDownload(id) {
  const response = await get(pdfDataPreflightPretestPath(id));
  saveUrlFile(response.data.url, response.data.name);
}

function preflightProcessingStatus(records) {
  const {
    totalChecks, completedChecks, noGuidelineData,
  } = records.reduce(
    (acc, obj) => {
      const { ruleResults } = obj;
      const { scores } = obj;

      if (ruleResults && typeof ruleResults === 'object') {
        acc.totalChecks += Object.keys(ruleResults).length;
        acc.completedChecks += Object.values(ruleResults).filter((value) => value !== null).length;
      }

      if (!scores) {
        acc.noGuidelineData += 1;
      }

      return acc;
    },
    {
      totalChecks: 0,
      completedChecks: 0,
      noGuidelineData: 0,
    },
  );

  if (records[0].isPreflightReviewed) {
    return 'Completed';
  } else if (noGuidelineData > 0) {
    return 'Processing';
  } else if (completedChecks === totalChecks) {
    return 'Generating PDF';
  }
  return `Processing - ${Math.round((completedChecks / totalChecks) * 100)}%`;
}

function rulesMetString(record) {
  if (record.scores && record.isReviewed) {
    return `${Object.values(record.ruleResults).filter((val) => val !== undefined && val === 1).length}/${Object.keys(record.ruleResults).length}`;
  }
  return NULL_VALUE;
}

function scoreString(record, scoreId) {
  if (record.scores && record.isReviewed) {
    if (record.scores[scoreId].value) {
      return `${Math.round(record.scores[scoreId].value * 100)}%`;
    }
    return 'N/A';
  }
  return NULL_VALUE;
}

function brandResultString(record) {
  if (record.scores && record.isReviewed) {
    return record.brandConsistent;
  }
  return NULL_VALUE;
}

function excellentCreativesString(record, scoreId) {
  if (record.scores && record.isReviewed) {
    return record.scores[scoreId].rank === HIGHEST_VALUE;
  }
  return NULL_VALUE;
}

function SubmittedPreflights({
  creativeLifecycle: {
    coreAssetIds,
    creativeLifecycleCampaigns,
    measurementPartners,
    partners,
  },
  currentTab,
  featureAccess: {
    canViewTabs,
    displayBrand,
    displayQuality,
    isCreativeLifecycleEnabled,
  },
  scores: {
    rankLabels,
    scoreId,
    scoreName,
  },
  userId,
  youtubeType,
}) {
  const currentTabLabel = currentTab === ALL_TAB ? 'All Submissions' : 'My Submissions';
  const segments = [{
    label: 'Brand',
    value: 'brand',
  }, {
    label: 'Channel',
    value: 'channel',
  }, {
    label: 'Filename',
    value: 'creativeName',
  }, {
    label: 'Market',
    value: 'market',
  }, {
    label: 'Partner',
    value: 'partner',
  }, {
    label: 'Submission Name',
    value: 'auditName',
  }, {
    label: `${scoreName}: Tiers`,
    value: 'rank',
  }];

  const [allChecked, setAllChecked] = useState(false);
  const [auditPosts, setAuditPosts] = useState([]);
  const [coreAssetSelectVisible, setCoreAssetSelectVisible] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedAuditIds, setSelectedAuditIds] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [tableDataWithParents, setTableDataWithParents] = useState([]);
  const [rows, setRows] = useState([]);
  const [expandedIds, setExpandedIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(initialDateRange());
  const [preflights, setPreflights] = useState([]);

  const handleDateRangeChange = (date, dateLabel) => {
    setSelectedDateRange((prev) => (
      {
        ...prev,
        [dateLabel]: date,
      }
    ));
  };

  function getCreativeName(record, isChild) {
    if (isChild) {
      if (record.creativeSourceType === youtubeType && record.title) {
        return record.title;
      }
      return record.filename || record.creativeThumbnail.split('/').slice(-1)[0];
    }
    return record.auditName;
  }

  function getRank(record) {
    return record.scores ? rankLabels[record.scores[scoreId].rank] : undefined;
  }

  // Each pretest from the backend is technically a post, and when
  // we have multiple posts in an audit, we need to create a parent row.
  // This function also assigns the values to each parent and child
  // row for display.
  const addParentRows = (childRows) => Object.entries(
    Object.groupBy(childRows, ({ auditId }) => auditId),
  ).map(([auditId, records]) => {
    // Use the first entry for the audit fields that
    // are the same for all posts (channel, submitted, etc...)
    const post = records[0];
    const singlePostReviewed = post.isReviewed && post.scores;
    if (records.length > 1) {
      // Assign values based on the summary data
      const extendedRecords = records.map((record) => ({
        ...record,
        brandResult: brandResultString(record),
        creativeName: getCreativeName(record, true),
        excellentCreatives: excellentCreativesString(record, scoreId),
        id: `${record.auditId}_${record.postId}`,
        isChild: true,
        isVisible: true,
        rank: getRank(record),
        rulesMet: rulesMetString(record),
        score: scoreString(record, scoreId),
      }));

      // If there are child records, we need to create a parent record
      // Default is that the post is not reviewed, in which case we cannot summarize columns
      const parentRecord = {
        brandConsistent: NULL_VALUE,
        brandResult: NULL_VALUE,
        channel: post.channel,
        children: extendedRecords,
        creativeName: getCreativeName(post, false),
        creativeThumbnail: post.creativeThumbnail,
        excellentCreatives: NULL_VALUE,
        id: auditId,
        isPreflightReviewed: post.isPreflightReviewed,
        pretestProcessingStatus: preflightProcessingStatus(records),
        regulatoryResult: NULL_VALUE,
        rulesMet: NULL_VALUE,
        score: NULL_VALUE,
        submittedTime: post.submittedTime,
      };

      // If the post is reviewed, the parent can summarize the child columns
      if (post.isPreflightReviewed) {
        const totalCreatives = extendedRecords.length;
        const excellentCreatives = extendedRecords.filter((hash) => hash.excellentCreatives).length;
        const missingBrandCreativeCount = extendedRecords.filter(
          (hash) => hash.brandConsistent === undefined,
        ).length;
        const brandedCreativeCount = extendedRecords.filter(
          (hash) => hash.brandConsistent === true,
        ).length;

        parentRecord.excellentCreatives = `${excellentCreatives}/${totalCreatives}`;

        if (missingBrandCreativeCount !== totalCreatives) {
          parentRecord.brandResult = `${brandedCreativeCount}/${totalCreatives}`;
        }

        parentRecord.score = 'Multiple'; // exposed on the child row
        parentRecord.rulesMet = 'Multiple'; // exposed on the child row
      }
      return parentRecord;
    } else if (singlePostReviewed) {
      // This case is for when the audit post has run through
      // the guideline data worker so guidelineData is populated.
      return {
        ...post,
        brandResult: post.brandConsistent ? post.brandConsistent : NULL_VALUE,
        creativeName: getCreativeName(post, false),
        excellentCreatives: post.scores[scoreId].rank === HIGHEST_VALUE,
        id: `${post.auditId}_${post.postId}`,
        pretestProcessingStatus: preflightProcessingStatus(records),
        rank: getRank(post),
        rulesMet: rulesMetString(post),
        score: scoreString(post, scoreId),
      };
    }
    // This case is for when the audit post hasn't run through the guideline data worker yet
    // so guidelineData hasn't been populated yet and will be nil.
    return {
      ...post,
      brandResult: NULL_VALUE,
      creativeName: getCreativeName(post, false),
      excellentCreatives: NULL_VALUE,
      id: `${post.auditId}${ID_DELIM}${post.postId}`,
      pretestProcessingStatus: preflightProcessingStatus(records),
      rank: post.scores ? rankLabels[post.scores[scoreId].rank] : undefined,
      rulesMet: NULL_VALUE,
      score: NULL_VALUE,
    };
  });

  // Run when the date range is set to grab the
  // time-filtration data
  useEffect(() => {
    (async () => {
      if (selectedDateRange) {
        setLoading(true);
        const response = await get(preflightsPreflightPretestsPath({
          selectedDateRange,
          tab: currentTab,
        }));

        if (response.isError) {
          const hasTimeout = response.status === 504;
          const message = hasTimeout ? 'The data request has timed out' : 'The data could not be loaded';

          addToast(message, { type: 'error' });
          setPreflights([]);
        } else {
          setPreflights(response.data.preflights);
        }
        setLoading(false);
      }
    })();
    setAllChecked(false);
    setSelectedAuditIds([]);
  }, [selectedDateRange]);

  // Run once during the initial data load to add the
  // parent rows
  useEffect(() => {
    const parentRows = addParentRows(preflights, scoreId);
    setTableDataWithParents(parentRows);

    // Expanded data needed to get all filter segments
    const tableData = [];
    parentRows.forEach(
      (data) => {
        if (data.children) {
          tableData.push(...data.children);
        }
        tableData.push(data);
      },
    );
    setAuditPosts(tableData);
  }, [preflights]);

  // Run every time a filter or search is changed to
  // select the appropriate subsection from the table data
  useEffect(() => {
    setPage(1);
    const filterTest = getObjFilterTest(selectedFilters);
    const lowercaseSearch = search.toLowerCase();
    const newExpandedIds = [];

    const filtered = tableDataWithParents.reduce((arr, item) => {
      const itemName = item.creativeName.toLowerCase();
      const itemNameInSearch = itemName.includes(lowercaseSearch);

      if (item.children) {
        const filteredChildren = item.children.filter((child) => {
          const childName = child.creativeName.toLowerCase();
          const inSearch = search !== '' ? itemNameInSearch || childName.includes(lowercaseSearch) : true;

          return filterTest(child) && inSearch;
        });

        if (filteredChildren.length > 0) {
          // If there is a search term or selected filters,
          // we want to expand all of the children of all parents
          if (search.length > 0 || Object.keys(selectedFilters).length > 0) {
            newExpandedIds.push(item.id);
          }
          return [
            ...arr,
            {
              ...item,
              children: filteredChildren,
            },
          ];
        }
      } else if (filterTest(item) && (search === '' || itemNameInSearch)) {
        return [
          ...arr,
          item,
        ];
      }
      return [...arr];
    }, []);

    setFilteredRecords(filtered.sort((preflight) => preflight.submittedTime).reverse());
    setExpandedIds(newExpandedIds);
  }, [selectedFilters, search, tableDataWithParents]);

  const formatRow = (item) => {
    const {
      assetId,
      brandResult,
      channel,
      children,
      excellentCreatives,
      id,
      index,
      isChild,
      isPreflightReviewed,
      isVisible,
      rulesMet,
      score,
      submittedTime,
    } = item;

    // NOTE: Using css to hide non-expanded child rows so
    // that the materialize creative modal doesn't break.
    const visibilityClass = classNames({
      [styles.hiddenCell]: isChild && !isVisible,
    });
    let conditionalCells = [];
    if (displayBrand) conditionalCells.push({ value: renderResultCell(brandResult) });
    if (displayQuality) {
      conditionalCells.unshift({ value: renderResultCell(excellentCreatives) });
      conditionalCells = conditionalCells.concat([
        { value: score },
        { value: rulesMet },
      ]);
    }

    let expandButton = '';
    if (children?.length > 0) {
      const onClick = () => {
        if (expandedIds.includes(id)) {
          setExpandedIds(expandedIds.filter((elem) => elem !== id));
        } else {
          setExpandedIds(expandedIds.concat(id));
        }
      };

      expandButton = (
        <Button
          iconLeft={expandedIds.includes(id) ? 'chevronDown' : 'chevronRight'}
          variant="round"
          onClick={onClick}
        />
      );
    }

    let downloadButton = '';
    if (!isChild && isPreflightReviewed) {
      downloadButton = <Button iconLeft="download" variant="tertiary" onClick={() => onPdfDownload(id.includes(ID_DELIM) ? id.split(ID_DELIM)[0] : id)} />;
    }

    const row = {
      key: `${id}_${index}`,
      cells: [
        ...arrayIf(coreAssetSelectVisible, {
          value: <Checkbox
            checked={selectedAuditIds.includes(id)}
            onChange={() => {
              if (children?.length > 0) {
                const ids = children.map((elem) => elem.id);
                if (selectedAuditIds.includes(id)) {
                  setSelectedAuditIds(
                    selectedAuditIds.filter((elem) => elem !== id && !ids.includes(elem)),
                  );
                } else {
                  setSelectedAuditIds(selectedAuditIds.concat(id).concat(...ids));
                }
              } else if (selectedAuditIds.includes(id)) {
                setSelectedAuditIds(selectedAuditIds.filter((elem) => elem !== id));
              } else {
                setSelectedAuditIds(selectedAuditIds.concat(id));
              }
            }}
          />,
        }),
        { value: expandButton },
        { value: <CreativeCell item={item} /> },
        {
          value: new Date(submittedTime).toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
        },
        { value: channel },
        ...conditionalCells,
        { value: downloadButton },
        ...arrayIf(coreAssetIds.includes(assetId) && coreAssetSelectVisible, { value: <Tag label="Core Asset" /> }),
      ],
    };

    return {
      ...row,
      cells: row.cells.map((cell) => ({
        ...cell,
        className: visibilityClass,
      })),
    };
  };

  const expandChildren = (parent) => {
    const finalRows = [formatRow(parent)];
    if (expandedIds.includes(parent.id)) {
      parent.children.forEach((child) => finalRows.push(formatRow({
        ...child,
        index: parent.index,
      })));
    }
    return finalRows;
  };

  // Run every time the filtered records change or the page
  // changes to show the correct paginated data
  useEffect(() => {
    setPaginatedData(filteredRecords.slice(
      (page - 1) * MAX_PER_PAGE,
      page * MAX_PER_PAGE,
    ));
  }, [page, filteredRecords]);

  // Run every time the select-all checkbox
  // is clicked, to select all filtered items
  useEffect(() => {
    if (allChecked) {
      let allIds = [];
      filteredRecords.forEach(({ id, children }) => {
        if (children) {
          allIds = allIds.concat(...children.map((child) => child.id));
        }
        allIds = allIds.concat(id);
      });
      setSelectedAuditIds(allIds);
    } else {
      setSelectedAuditIds([]);
    }
  }, [allChecked]);

  useEffect(() => {
    setExpandedIds(paginatedData.map(({ id, children }) => (children ? id : undefined)));
  }, [coreAssetSelectVisible]);

  // Run every time the paginated data changes, to actually build the rows
  // displayed in the table.
  useEffect(() => {
    setRows(
      paginatedData
        .map((data, index) => ({
          ...data,
          index,
        }))
        .map(expandChildren)
        .flat(),
    );
  }, [paginatedData, coreAssetSelectVisible, expandedIds, selectedAuditIds]);

  const menuOptions = [
    {
      label: 'Select Core Assets',
      onClick: () => setCoreAssetSelectVisible(true),
    },
  ];

  const tabs = [
    {
      label: 'My Submissions',
      onClick: () => {
        track('preflight_tab_switch', { tab: USER_TAB });
        window.location.href = preflightPretestsPath({ tab: USER_TAB });
      },
    },
    ...arrayIf(canViewTabs, {
      label: 'All Submissions',
      onClick: () => {
        track('preflight_tab_switch', { tab: ALL_TAB });
        window.location.href = preflightPretestsPath({ tab: ALL_TAB });
      },
    }),
  ];

  return (
    <>
      <DsTabs
        currentTab={currentTabLabel}
        tabs={tabs}
        variant="button"
        showSingleTab
      />
      <Card>
        <div className={styles.header}>
          <div className={styles.headerRow}>
            <Search
              placeholder="Search Name"
              value={search}
              onChange={setSearch}
            />
            <PreflightsFilter
              isOpen={filterOpen}
              records={auditPosts}
              segments={segments}
              selections={selectedFilters}
              onClose={() => setFilterOpen(false)}
              onOpen={() => setFilterOpen(true)}
              onSelectionsChange={setSelectedFilters}
            />
          </div>
          <div className={styles.headerRow}>
            { coreAssetSelectVisible
              && (
              <CoreAssetButton
                disabled={selectedAuditIds.length === 0}
                onAddToCoreAssets={() => setModalOpen(true)}
                onCancel={() => setCoreAssetSelectVisible(false)}
              />
              ) }
            { isCreativeLifecycleEnabled && !coreAssetSelectVisible && paginatedData.length > 0
              && <MoreButton options={menuOptions} testId="menu" /> }
            <DatePicker
              disabled={loading}
              endDate={selectedDateRange.endDate}
              localStorageKey={userId.toString()}
              maxDate={dateToString(new Date())}
              minDate={dateToString(new Date(new Date().getFullYear() - DATE_GAP, 0, 1))}
              startDate={selectedDateRange.startDate}
              onEndDateChange={(date) => {
                track('preflight_date_range_change', {
                  dateLabel: 'endDate',
                  date,
                });
                handleDateRangeChange(date, 'endDate');
              }}
              onStartDateChange={(date) => {
                track('preflight_date_range_change', {
                  dateLabel: 'startDate',
                  date,
                });
                handleDateRangeChange(date, 'startDate');
              }}
            />
            { !coreAssetSelectVisible
              && <AddNewButton label="Submit New" onClick={() => { window.location.href = newPreflightPretestPath(); }} /> }
          </div>
        </div>
        <div className={styles.filterRow}>
          <FilterTags
            dimensions={segments}
            selections={selectedFilters}
            onClick={() => setFilterOpen(true)}
            onRemove={(filters) => setSelectedFilters(filters)}
          />
        </div>
        <Divider margin />
        <SubmittedPreflightsTable
          headers={headers(
            displayBrand,
            displayQuality,
            allChecked,
            () => setAllChecked(!allChecked),
            coreAssetSelectVisible && filteredRecords.length <= MAX_SELECTABLE_ASSETS,
          )}
          loading={loading}
          maxPerPage={MAX_PER_PAGE}
          page={page}
          rows={rows}
          total={filteredRecords.length}
          onPageChange={setPage}
        />
      </Card>
      <SubmitAsCoreAssetModal
        creativeLifecycleCampaigns={creativeLifecycleCampaigns}
        isOpen={modalOpen}
        measurementPartners={measurementPartners}
        partners={partners}
        selectedAuditIds={selectedAuditIds}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
}

SubmittedPreflights.propTypes = propTypes;
SubmittedPreflights.defaultProps = defaultProps;

export default SubmittedPreflights;
